import React, { useState, useEffect } from "react";
import "./header.css";
import { NavLogo } from "../assets";
import { ReactComponent as MenuIcon } from "../../assets/menu.svg";
import { ReactComponent as LogoIcon } from "../../assets/modalLogo.svg";
import { ReactComponent as CloseIcon } from "../../assets/close-menu.svg";

const Header = ({ onContactClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth]);

  return (
    <div className="video-container">
      <video className="video no-controls" autoPlay loop muted playsInline>
        <source src={require("../../assets/RE.mp4")} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="overlay-bg">
        <div>
          <div className="navbar-container">
            <div className="logo-div">
              <img src={NavLogo} className="logo" alt="logo" />
              <p className="logo-text">MY RE TASKER</p>
            </div>
            <div className="auth-btn-combo">
              <button
                className="login-btn "
                onClick={() => {
                  window.location.href = "https://dev.myretasker.com/#/sign-in";
                }}
              >
                Log In
              </button>
              <button
                className="signup-btn"
                onClick={() => {
                  window.location.href = "https://dev.myretasker.com/#/sign-up";
                }}
              >
                Sign Up
              </button>
            </div>
            <div className="menu-icon-nav" onClick={togglePopup}>
              <MenuIcon />
            </div>
          </div>
        </div>
        <div className="bottom-right">
          <p className="service-text">
            Enhancing Real Estate Agents’ Efficiency with Customized Support
            Services
          </p>
          <button className="btn" onClick={onContactClick}>
            Contact Us
          </button>
        </div>
      </div>
      {isOpen && windowWidth <= 567 && (
        <div className="popup-overlay">
          <div className="popup-content">
            <div className="popup-header">
              <div className="logo-div">
                <LogoIcon />
                <p className="logo-text" style={{ color: "#34424B" }}>
                  MY RE TASKER
                </p>
              </div>
              <div style={{ cursor: "pointer" }} onClick={togglePopup}>
                <CloseIcon />
              </div>
            </div>
            <div className="popup-buttons">
              <button
                className="modal-login-btn "
                onClick={() =>
                  (window.location.href =
                    "https://dev.myretasker.com/#/sign-in")
                }
              >
                Log In
              </button>
              <button
                className="modal-signup-btn"
                onClick={() =>
                  (window.location.href =
                    "https://dev.myretasker.com/#/sign-up")
                }
              >
                Sign Up
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
